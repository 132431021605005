import React from 'react'
import { Layout, LogoBanner } from '../Shared'
import helmetProps from './helmetProps'
import SplashContainer from './sections/SplashContainer'
import ValueProps from './sections/ValueProps'
import Testimonials from './sections/Testimonials'
import breakpoints, { getViewType } from './breakpoints'
import './Landing.global.scss'
import { IrisCTALinkButton } from '../Shared'

class LandingView extends React.PureComponent {
  render() {
    const {
      widthClass,
      width,
      trustedImages,
      productImages,
      trustedTestimonialImages,
      featuredTestimonialImages,
      valuePropsImages,
    } = this.props

    const viewType = getViewType(widthClass)
    const { isStackedNarrow, isStackedWide } = viewType
    const shouldStack = isStackedNarrow || isStackedWide
    const extraSmall = width < 768

    return (
      <div id="landing">
        <SplashContainer browserWidth={width} />
        <LogoBanner
          largeWidth={!extraSmall}
          images={trustedImages}
          type="trusted"
        />
        <ValueProps
          shouldStack={shouldStack}
          files={valuePropsImages}
          valuePropsImages={valuePropsImages}
        />
        <Testimonials
          widthClass={widthClass}
          trustedTestimonialImages={trustedTestimonialImages}
          featuredTestimonialImages={featuredTestimonialImages}
        />
      </div>
    )
  }
}

const MeetCustomers = () => (
  <div style={{ textAlign: 'center', marginTop: '2.0em' }}>
    <IrisCTALinkButton
      href={'customers/'}
      cta={'Meet our Customers'}
      customStyles={{ backgroundColor: 'transparent' }}
      theme={'inverse'}
    />
  </div>
)

class LandingPage extends React.Component {
  render() {
    return (
      <Layout breakpoints={breakpoints}>
        <LandingView {...this.props} />
      </Layout>
    )
  }
}

export default LandingPage

export { helmetProps }
