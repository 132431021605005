import React from 'react'
import Banner from '../Banner'
import './banners.global.scss'

const Autodesk10112023 = ({ width }) => (
  <Banner
    bannerID="autodesk-10112023"
    ctaText=""
    ctaLink=""
    view={width > 500 ? 'banner-desktop' : 'banner-mobile'}
  >
    New! From the team who brought you Prospect by IrisVR: Autodesk Workshop XR, an immersive design review workspace for AEC teams. {' '}
    <a id="iris-autodesk" href="https://workshopxr.autodesk.com/">
    Learn&nbsp;more
    </a>
    .
  </Banner>
)

export default Autodesk10112023