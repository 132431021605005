import React from 'react'
import { Link } from 'gatsby'
import './Navbar.scss'

const sectionsData = {
  integrations: {
    sectionTitle: 'Integrations',
    sectionLinks: {
      navisworks: {
        title: 'Navisworks',
        to: '/integrations/navisworks',
      },
      revit: {
        title: 'Revit',
        to: '/integrations/revit',
      },
      rhino: {
        title: 'Rhino',
        to: '/integrations/rhino',
      },
      sketchup: {
        title: 'SketchUp',
        to: '/integrations/sketchup',
      },
    },
  },
  headsets: {
    sectionTitle: 'Headset Support',
    sectionLinks: {
      quest: {
        title: 'Meta Quest',
        to: '/oculus-quest',
      },
      all: {
        title: 'View All',
        to: '/supported-headsets',
      },
    },
  },
  industries: {
    sectionTitle: 'Industries',
    sectionLinks: {
      construction: {
        to: '/industry/construction',
        title: 'Construction',
      },
      engineering: {
        to: '/industry/engineering',
        title: 'Engineering',
      },
      architecture: {
        to: '/industry/architecture',
        title: 'Architecture',
      },
      educators: {
        to: '/industry/educators',
        title: 'Educators',
      },
      students: {
        to: '/industry/students',
        title: 'Students',
      },
    },
  },
}

const SectionHeader = ({ title }) => {
  return (
    <div
      className="light-gray"
      style={{
        paddingBottom: 20,
        textTransform: 'uppercase',
        fontSize: 12,
        paddingLeft: 30,
      }}
    >
      {title}
    </div>
  )
}

const SubsectionLink = ({ title, to }) => {
  return (
    <Link
      className="black-hover-gray"
      to={to}
      style={{
        textDecoration: 'none',
        width: '100%',
        display: 'block',
        paddingLeft: 30,
      }}
    >
      {title}
    </Link>
  )
}

const SectionContainer = ({ lastSection, children }) => {
  return (
    <div
      className={lastSection ? '' : `right-gray-border`}
      style={{
        paddingTop: 20,
        paddingBottom: 30,
        minWidth: '185px',
      }}
    >
      {children}
    </div>
  )
}

const SectionLinks = ({ sectionLinksData }) => {
  return (
    <div style={{ fontSize: 16 }}>
      {Object.values(sectionLinksData).map((subsectionLinkData, idx) => {
        const subsectionLinkKey = Object.keys(sectionLinksData)[idx]
        return (
          <div key={`${subsectionLinkKey}-${idx}`} style={{ paddingBottom: 7 }}>
            <SubsectionLink {...subsectionLinkData} />
          </div>
        )
      })}
    </div>
  )
}

const Sections = () => {
  const sections = Object.values(sectionsData)
  return sections.map(({ sectionTitle, sectionLinks }, idx) => {
    const sectionKey = Object.keys(sectionsData)[idx]
    const lastSection = idx === 2
    return (
      <SectionContainer key={`${sectionKey}-${idx}`} lastSection={lastSection}>
        <SectionHeader title={sectionTitle} />
        <SectionLinks sectionLinksData={sectionLinks} />
      </SectionContainer>
    )
  })
}

const ProspectOverviewLink = () => {
  return (
    <Link
      className="bottom-gray-border black-hover-gray"
      style={{
        padding: 20,
        paddingLeft: 30,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        textDecoration: 'none',
        fontSize: 16,
      }}
      to="/prospect"
    >
      Prospect Overview
    </Link>
  )
}

const ProductDropdownContents = () => {
  return (
    <div>
      <ProspectOverviewLink />
      <div style={{ display: 'flex' }}>
        <Sections />
      </div>
    </div>
  )
}

export default ProductDropdownContents
